import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { all_ActionsRequired } from './api';

const initialState = {
  all_actions: []
};

const actions = {
  GETACTIONSREQUIRED: 'getActionsRequired/GETACTIONSREQUIRED'
};

export const getActionsRequired = createAsyncThunk(
  actions.GETACTIONSREQUIRED,
  async (payload) => {
    const response = await all_ActionsRequired('all-envelopes', payload);
    return response;
  }
);

export const getActionsSlice = createSlice({
  name: 'actionsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActionsRequired.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActionsRequired.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_actions = data;
      })
      .addCase(getActionsRequired.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getTrackersSlice.actions; */
/* export const { resetTrackers } = getTrackersSlice.actions; */

export default getActionsSlice.reducer;
