import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
// console.log('apiUrl', apiUrl);
const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('gnfa_user');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    signin: `${apiUrl}/auth/sign-in`,
    resetPassword: `${apiUrl}/auth/forgot-password`,
    setPassword: `${apiUrl}/auth/reset-password`,
    // locations: `${apiUrl}masters/location-drop-down`,
    plants: `${apiUrl}masters/plants-list`,
    dashboardCount: `${apiUrl}user/dashboard-counts`,
    functions: `${apiUrl}masters/department-drop-down`,
    approvers: `${apiUrl}user/users-lists`,
    filtercount: `${apiUrl}envelopes/filter-counts`,
    create_envelop: `${apiUrl}envelopes/create-envelopes`,
    dataList: `${apiUrl}envelopes/all-envelopes`,
    all_actions_required: `${apiUrl}envelopes/one-envelope`,
    categorylist: `${apiUrl}masters/category-lists`,
    masterlist: `${apiUrl}masters`
    // materialList: `${apiUrl}/material`,
    // document: `${apiUrl}/document`,
    // weighing: `${apiUrl}/weighments`,
    // damaged: `${apiUrl}/damage`,
    // supplierslist: `${apiUrl}/suppliers`,
    // qc: `${apiUrl}/qc`,
    // lc: `${apiUrl}/lc`,
    // printLable: `${apiUrl}/user`,
    // vehicle: `${apiUrl}/vehicle-storage`,
    // batch: `${apiUrl}/batch`,
    // freeContainers: `${apiUrl}/bin`,
    // dispense: `${apiUrl}/dispense`,
    // container: `${apiUrl}/container`,
    // wastage: `${apiUrl}/wastage`,
    // sampling: `${apiUrl}/sampling`,
    // stocks: `${apiUrl}/stock`,
    // admin: `${apiUrl}/admin`,
    // product: `${apiUrl}/product`,
    // granulation: `${apiUrl}/product_granulation`,
    // sifting: `${apiUrl}/shift_and_milling`,
    // extraGranulation: `${apiUrl}/extra_granular`,
    // blending: `${apiUrl}/blending`,
    // prodSampling: `${apiUrl}/sampling_products`,
    // yieldRecon: `${apiUrl}/yield_reconsiliation`,
    // blendDistribution: `${apiUrl}/blend_distribution`,
    // trf: `${apiUrl}/trf`,
    // balance: `${balanceUrl}/balance`,
    // returnQty: `${apiUrl}/returned_qty`,
    // productWeighing: `${apiUrl}/prodweighing`,
    // bomProdUser: `${apiUrl}/bom_prod_user`,
    // bmrExecution: `${apiUrl}/bmr_exec`,
    // print: `${apiUrl}`,
    // batchLog: `${apiUrl}/batch_log_book`,
    // audit: `${apiUrl}/audit_log`,
    // transfer: `${apiUrl}/code_transfer`,
    // department: `${apiUrl}/department`,
    // lcBmr: `${apiUrl}/lc_bmr`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
