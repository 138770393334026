import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import { ClockCircleOutlined } from '@ant-design/icons';

import { Badge, Menu, Popover, Table } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getActionsRequired } from '../../redux/slice';
import styles from './index.module.less';

const ActionsRequiredPage = ({ selectedStatus, setSelectedStatus }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'icons',
      key: 'id',
      render: () => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {<ClockCircleOutlined />}
            </div>
          </>
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'subject',
      key: 'id',
      width: '40%',
      render: (subject) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">{subject.title}</b>
          </div>
        </>
      )
    },
    {
      title: 'Accepters',
      // dataIndex: 'description',
      dataIndex: 'subject',
      key: 'subject',
      width: '60%',
      render: (subject) => (
        <>
          <div className="mb-1 mr-5">
            <div>
              To:{' '}
              {subject?.envelope_approvers?.length > 0
                ? subject.envelope_approvers
                    .slice(0, 10)
                    .map(
                      (item) =>
                        `${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`
                    )
                    .join(', ')
                : 'N/A'}
              {'\u00a0'}
              {/* {record?.project_name ? `${' || '}${record?.project_name}` : ''} */}
              <Popover
                content={
                  <>
                    <h2 className="sfprotext-bold">Approvers</h2>
                    {subject?.envelope_approvers?.map((item, index) => (
                      <p key={index}>
                        {`${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`}
                      </p>
                    ))}
                  </>
                }
                placement="rightTop"
              >
                {subject?.envelope_approvers?.length > 2 && (
                  <Badge
                    className={`site-badge-count-109 ${styles.badge}`}
                    count={`+ ${subject.envelope_approvers.length - 2}`}
                  />
                )}
              </Popover>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'id',
      width: '20%',
      render: (actions) => {
        return (
          <>
            <Link to={'review-actions/' + `${actions.id}`}>
              <CustomButton type="primary" className={`${styles.use_button}`}>
                {'Review'}
              </CustomButton>
            </Link>
          </>
        );
      }
    }
  ];
  const [setSearch] = useState('');
  // search,
  const { all_data } = useSelector((state) => state.trackersList);
  // state.actionsList
  // all_actions
  const all_actions = all_data;
  console.log('all_actions', all_actions);
  const data = all_actions?.map((item) => {
    // const { accepters, name, id, created_by, title } = item;
    const {
      accepters,
      created_by,
      status,
      total_approvers_count,
      present_approvers_count,
      title,
      type,
      envelope_approvers,
      id
    } = item;
    //   return {
    //     ...item,
    //     subject: {
    //       id,
    //       name,
    //       created_by,
    //       title
    //     },
    //     description: {
    //       id,
    //       accepters: accepters
    //     },
    //     actions: {
    //       id
    //     }
    //   };
    // });
    return {
      ...item,
      subject: {
        // accepters: JSON.parse(accepters),
        accepters: accepters,
        envelope_approvers: envelope_approvers,
        created_by,
        title,
        type,
        id
      },
      description: {
        total_approvers_count,
        status,
        present_approvers_count
      },
      actions: {
        id
      }
    };
  });

  const dispatch = useDispatch();
  // useEffect(() => {
  //   handleGetStats();
  // }, [search,]);
  // const handleGetStats = () => {
  //   let payload = search !== '' ? { search: search } : {};
  //   dispatch(getActionsRequired(payload));
  // };
  useEffect(() => {
    handleAllData();
  }, []);

  const handleAllData = () => {
    let payload = {
      type: 'Initiated By Others',
      category: 'pending'
    };
    dispatch(getActionsRequired(payload));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header justify="space-between" align="middle" buttons={[]} />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by envelope or project'
            }}
            filters={true}
            statusMenu={statusMenu}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <Table
          rowKey={(obj) => obj.id}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default ActionsRequiredPage;
