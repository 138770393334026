import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import { ClockCircleOutlined, DownOutlined } from '@ant-design/icons';

import { Menu, Table, Popover, Badge } from 'antd';
// Progress
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackersList, getAllData } from '../../admin-manage/redux/slice';
import styles from './index.module.less';

const AdminManagePage = ({ selectedStatus, setSelectedStatus }) => {
  /* const data = [
    {
      key: '1',
      subject: {
        name: 'John Brown 2',
        to: ['People', 'people1', 'people2', 'people3']
      },
      description: {
        title: 'Product Catalog',
        message:
          'Export - Need to change description column data in exported file as it includes product code'
      }
    },
    {
      key: '2',
      subject: {
        name: 'John Brown 2',
        to: ['People', 'people1', 'people2', 'people3']
      },
      description: {
        title: 'Product Catalog',
        message:
          'Export - Need to change description column data in exported file as it includes product code'
      }
    },
    {
      key: '3',
      subject: {
        name: 'John Brown 2',
        to: ['People', 'people1', 'people2', 'people3']
      },
      description: {
        title: 'Product Catalog',
        message:
          'Export - Need to change description column data in exported file as it includes product code'
      }
    }
  ];
 */
  // const gcd = function (a, b) {
  //   if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

  //   return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  // };

  // const final = Math.floor(5) + '/' + Math.floor(5);
  const columns = [
    {
      title: '',
      dataIndex: 'icons',
      render: () => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {<ClockCircleOutlined />}
            </div>
          </>
        );
      }
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '45%',
      render: (subject) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              {/* <a
                href={`actions-required/review-actions/${record.id}`}
                target="__blank"
              > */}
              {subject.title}
              {/* {`# Env-${
                  record['envelope_flow.envelope_flows_id']
                    ? record['envelope_flow.envelope_flows_id']
                    : record?.id
                  // record?.envelope_flows_id
                }`} */}
              {/* </a> */}
            </b>
          </div>
          <div>
            To:{' '}
            {/* {subject?.envelope_approvers
              ? subject.envelope_approvers.slice(0, 2).map((item) => item.name).join`, `
              : subject.accepters.map((item) => item.name).join`, `} */}
            {subject?.envelope_approvers?.length > 0
              ? subject.envelope_approvers
                  .slice(0, 10)
                  .map(
                    (item) =>
                      `${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`
                  )
                  .join(', ')
              : 'N/A'}
            {'\u00a0'}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {subject?.envelope_approvers?.map((item, index) => (
                    <p key={index}>
                      {`${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`}
                    </p>
                  ))}
                </>
              }
              placement="rightTop"
            >
              {subject?.envelope_approvers?.length > 2 && (
                <Badge
                  className={`site-badge-count-109 ${styles.badge}`}
                  count={`+ ${subject.envelope_approvers.length - 2}`}
                />
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <div className="mr-5 mb-1">
            <h1>
              {/* <Progress
                size="small"
                // success={{ percent: 30 }}
                percent={30}
                strokeColor={'#1CB697'}
                status="active"
                format={gcd}
                showInfo={false}
              /> */}
            </h1>
            <div>{description.status}</div>
          </div>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: () => {
        return (
          <>
            <CustomButton className={`${styles.use_button} mr-2`}>
              Resend
            </CustomButton>

            <CustomButton className={`${styles.down_button}`}>
              <DownOutlined />
            </CustomButton>
          </>
        );
      }
    }
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { all_data } = useSelector((state) => state.trackersList);
  const all_trackers = all_data;
  console.log('all_trackers_pending', all_trackers);
  const data = all_trackers.map((item) => {
    const {
      accepters,
      created_by,
      status,
      total_approvers_count,
      present_approvers_count,
      title,
      type,
      envelope_approvers
    } = item;
    return {
      ...item,
      subject: {
        // accepters: JSON.parse(accepters),
        accepters: accepters,
        envelope_approvers: envelope_approvers,
        created_by,
        title,
        type
      },
      description: {
        total_approvers_count,
        status,
        present_approvers_count
      }
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
    handleAllData();
  }, []);
  const handleGetStats = () => {
    dispatch(getTrackersList());
  };

  const handleAllData = () => {
    let payload = {
      type: 'Initiated By Others',
      category: 'pending'
    };
    dispatch(getAllData(payload));
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header
          justify="space-between"
          align="middle"
          title="Sent"
          buttons={[]}
        />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            className="mt-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search'
            }}
            filters={true}
            statusMenu={statusMenu}
          />
        </div>
      </div>
      <div>
        <Table
          className={`${styles.custom_table}`}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default AdminManagePage;
