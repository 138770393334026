import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import leftTriangle from '../../images/leftTriangle.svg';
import flippedTriangle from '../../images/flippedTriangle.svg';
import flippedTriangle1 from '../../images/flippedTriangle1.svg';
import fullSquare from '../../images/fullSquare.svg';
import vrDots from '../../images/vrDots.svg';
import hrDots from '../../images/hrDots.svg';
import btDots from '../../images/btDots.svg';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';
import AdhocModal from '../AdhocModal';
import AddStandardModal from '../AddStandardModal';
import AddCustomModal from '../AddCustomModal';
import LocalStorage from 'utilities/localStorage';
import { Link } from 'react-router-dom';
import { getsubCategory } from '../../redux/slice';
import { useDispatch } from 'react-redux';

const DashBoardPage = ({
  setSearchSubCategory,
  setCategoryId,
  onSearchSubCategory,
  form,
  onSearchCategory,
  payloadDataForAllStandard,
  loading,
  stats,
  envelopeTypes,
  isModalVisible,
  isStandardModalVisible,
  onFinish,
  showModal,
  showStandardModal,
  handleCancel,
  handleStandardCancel,
  // showCustomdModal,
  isCustomModalVisible,
  handleCustomCancel,
  pricebrackets,
  categories,
  handleCategory,
  subcategories,
  handleSearchstandard,
  isStandardTemplateModalVisible,
  setIsStandardTemplateModalVisible,
  standardValues,
  handleStandardtemplateCancel,
  onSearch,
  onSelectProject,
  projects,
  onSelectBU,
  onSearchbu,
  bu,
  showProject,
  handleSubCategory,
  setCategory,
  category,
  type,
  // locations,
  plants,
  functions,
  subcategory
  // setStandardValues
}) => {
  const user = LocalStorage.getItem('gnfa_user');
  // const firstName = user?.user_details?.first_name;
  // console.log('user?.user_details?.first_name:', firstName);
  // console.log('locationssssssssss', functions);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChangeFromChild = (value) => {
    setSelectedCategory(value);
  };
  // console.log('subcategory', subcategory);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedCategory !== null) {
      // console.log('Selected Category in Parent:', selectedCategory);
      const payload = selectedCategory;
      // API call logic here based on selectedCategory
      dispatch(getsubCategory(payload));
    }
  }, [selectedCategory]);

  return (
    <>
      <Row>
        {/* <Alert
          message="The NFA App is being upgraded to align with the new DOA. The portal will be unavailable from May 1, 2024 to May 2, 2024."
          type="warning"
          showIcon
        /> */}
      </Row>
      <br />
      <Card hoverable className={`${styles.upper_card_container}`}>
        <img
          className={`${styles.left_triangle}`}
          src={leftTriangle}
          alt="lt"
        />
        <img className={`${styles.full_square}`} src={fullSquare} alt="fs" />
        <img className={`${styles.hr_dots}`} src={hrDots} alt="hd" />

        <Row>
          <Col span={4} xl={4} xs={24}>
            <div className={` mt-3 df-sb-ac ${styles.left_greeting}`}>
              Hi{' '}
              <b>
                {user?.user_details?.first_name
                  ?.split(' ')
                  ?.slice(0, 1)
                  ?.join(' ')}
                !
              </b>
            </div>
          </Col>
          {stats.map((item, index) => {
            const { label, value, link } = item;
            return (
              <React.Fragment key={index}>
                <Col
                  className="mt-3"
                  key={label}
                  xl={4}
                  sm={10}
                  xs={24}
                  span={4}
                >
                  <Link to={link}>
                    <Col
                      span={2}
                      className={`font-14   ${styles.upper_card_right_side}`}
                    >
                      {label}
                    </Col>

                    <Col className={` ${styles.upper_card_right_side_b}`}>
                      {value}
                    </Col>
                  </Link>
                </Col>

                {index != stats.length - 1 ? (
                  <Col className="mt-3" span={1}>
                    <div className={`${styles.upper_card_divider}`} />
                  </Col>
                ) : null}
              </React.Fragment>
            );
          })}
        </Row>
        <img
          className={`${styles.flipped_triangle1}`}
          src={flippedTriangle}
          alt="ft"
        />
        <img
          className={`${styles.flipped_triangle2}`}
          src={flippedTriangle1}
          alt="ft1"
        />
        <img className={`${styles.vr_dots}`} src={vrDots} alt="vd" />
        <img className={`${styles.bt_dots}`} src={btDots} alt="vd" />
      </Card>

      <Card hoverable className={` ${styles.lower_card_container} mt-3`}>
        <Row gutter={[48, 16]} className={`df-jc-ac`}>
          {envelopeTypes.map((item, index) => {
            const { title, subText, btnText, image } = item;
            // console.log('Current index:', index);
            return (
              <React.Fragment key={index}>
                <Col
                  xl={7}
                  sm={10}
                  xs={24}
                  key={title}
                  className={`df-jc-ac p-3`}
                  span={7}
                >
                  <div className={`${styles.item}`}>
                    <img src={image} />
                    <span className={`${styles.caption}`}>{title}</span>
                    <span className={`${styles.description}`}>{subText}</span>
                    <CustomButton
                      className={`${styles.lower_card_button}`}
                      onClick={
                        () =>
                          index === 0
                            ? showModal()
                            : // index === 0
                              // ?
                              showStandardModal(index)
                        // : showCustomdModal
                      }
                    >
                      {btnText}
                    </CustomButton>
                  </div>
                </Col>
                {index != envelopeTypes.length - 1 ? (
                  <div className={`${styles.vertical_divider}`} />
                ) : null}
              </React.Fragment>
            );
          })}
        </Row>
      </Card>

      {isModalVisible ? (
        <AdhocModal
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
          onFinish={onFinish}
          loading={loading}
          // locations={locations}
          plants={plants}
          functions={functions}
          category={category}
          onCategoryChange={handleCategoryChangeFromChild}
          subcategory={subcategory}
        />
      ) : null}
      {isStandardModalVisible ? (
        <AddStandardModal
          setSearchSubCategory={setSearchSubCategory}
          setCategoryId={setCategoryId}
          onSearchSubCategory={onSearchSubCategory}
          form={form}
          onSearchCategory={onSearchCategory}
          payloadDataForAllStandard={payloadDataForAllStandard}
          handleStandardCancel={handleStandardCancel}
          isStandardModalVisible={isStandardModalVisible}
          onFinish={onFinish}
          loading={loading}
          type={type}
          pricebrackets={pricebrackets}
          categories={categories}
          handleCategory={handleCategory}
          subcategories={subcategories}
          handleSearchstandard={handleSearchstandard}
          onSelectProject={onSelectProject}
          onSearch={onSearch}
          projects={projects}
          onSearchbu={onSearchbu}
          onSelectBU={onSelectBU}
          bu={bu}
          showProject={showProject}
          handleSubCategory={handleSubCategory}
          setCategory={setCategory}
          category={category}
          // locations={locations}
          plants={plants}
          functions={functions}
          subcategory={subcategory}
          onCategoryChange={handleCategoryChangeFromChild}
        />
      ) : null}
      {isCustomModalVisible ? (
        <AddCustomModal
          form={form}
          payloadDataForAllStandard={payloadDataForAllStandard}
          handleCustomCancel={handleCustomCancel}
          isCustomModalVisible={isCustomModalVisible}
          onFinish={onFinish}
          loading={loading}
          type={'custom'}
          standardValues={standardValues}
          // locations={locations}
          plants={plants}
          functions={functions}
          category={category}
          subcategory={subcategory}
          onCategoryChange={handleCategoryChangeFromChild}
        />
      ) : null}
      {isStandardTemplateModalVisible ? (
        <AddCustomModal
          setSearchSubCategory={setSearchSubCategory}
          setCategoryId={setCategoryId}
          onSearchSubCategory={onSearchSubCategory}
          form={form}
          onSearchCategory={onSearchCategory}
          payloadDataForAllStandard={payloadDataForAllStandard}
          handleCustomCancel={handleCustomCancel}
          isStandardTemplateModalVisible={isStandardTemplateModalVisible}
          setIsStandardTemplateModalVisible={setIsStandardTemplateModalVisible}
          onFinish={onFinish}
          loading={loading}
          type={'standard'}
          standardValues={standardValues}
          handleStandardtemplateCancel={handleStandardtemplateCancel}
          // locations={locations}
          plants={plants}
          functions={functions}
          category={category}
          subcategory={subcategory}
          onCategoryChange={handleCategoryChangeFromChild}
        />
      ) : null}
    </>
  );
};

export default DashBoardPage;
